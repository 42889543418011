import { useContext } from 'react';

import { UserContext } from 'eficia/contexts/UserProvider';

import alertsApi from './alerts';
import dataVisualizationApi from './dataVisualization';
import groupsApi from './groups';
import harmonyApi from './harmony';
import measurementsApi from './measurements';
import organizationApi from './organization';
import sitesApi from './sites';
import translationsApi from './translations';
import userApi from './user';
import usersApi from './users';

export default function useEficiaClient() {
  const { isConnected } = useContext(UserContext);

  return {
    measurements: measurementsApi(isConnected),
    organization: organizationApi(isConnected),
    sites: sitesApi(isConnected),
    groups: groupsApi(isConnected),
    users: usersApi(isConnected),
    alerts: alertsApi(isConnected),
    harmony: harmonyApi(isConnected),
    translations: translationsApi(isConnected),
    user: userApi(isConnected),
    dataVisualization: dataVisualizationApi(isConnected)
  };
}
