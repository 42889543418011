import React, { useEffect } from 'react';
import { CookiesProvider } from 'react-cookie';
import TagManager from 'react-gtm-module';
import { hotjar } from 'react-hotjar';
import { QueryClient, QueryClientProvider } from 'react-query';
import * as am5 from '@amcharts/amcharts5';
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ThemeProvider } from 'styled-components';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { LayoutProvider } from 'eficia/contexts/LayoutProvider';
import AppRoutes from 'eficia/routes/Routes';
import ErrorBoundary, { RootErrorPage } from 'eficia/views/error/ErrorBoundary';

import '../../../assets/base.scss';
import MuiTheme from '../../../theme';

library.add(fas, far);

export default function App() {
  // init config react-query
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  });

  function initHotjar() {
    hotjar.initialize(
      process.env.REACT_APP_HOTJAR_ID,
      process.env.REACT_APP_HOTJAR_SV,
      process.env.NODE_ENV === 'development'
    );
  }

  // ReactGA.initialize(process.env.REACT_APP_GA_KEY);

  // GTM
  function initGTM() {
    TagManager.initialize({
      gtmId: process.env.REACT_APP_GTM_KEY
      // dataLayerName: 'PageDataLayer'
    });
  }

  // amCharts 5
  am5.addLicense('AM5C345010209');

  function initAxeptio() {
    const el = document.createElement('script');
    el.setAttribute('src', 'https://static.axept.io/sdk-slim.js');
    el.setAttribute('type', 'text/javascript');
    el.setAttribute('async', 'true');
    el.setAttribute('data-id', process.env.REACT_APP_AXEPTIO_ID);
    el.setAttribute('data-cookies-version', 'app eficia-fr');

    if (document.body !== null) {
      document.body.appendChild(el);
    }

    window._axcb = window._axcb || [];
    window._axcb.push((axeptio) => {
      axeptio.on('cookies:complete', (choices) => {
        if (choices.googletagmanager) {
          initGTM();
        }

        if (choices.hotjar) {
          initHotjar();
        }

        window.hideAxeptioButton();
      });
    });
  }

  useEffect(() => {
    if (process.env.REACT_APP_EFICIA_ENVIRONMENT !== 'production') {
      initGTM();
      initHotjar();
    } else {
      initAxeptio();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary errorElement={<RootErrorPage />}>
      <QueryClientProvider client={queryClient}>
        <CookiesProvider>
          <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={MuiTheme}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <ThemeProvider theme={MuiTheme}>
                  <CssBaseline />
                  <LayoutProvider>
                    <ErrorBoundary errorElement={<RootErrorPage />}>
                      <AppRoutes />
                    </ErrorBoundary>
                  </LayoutProvider>
                </ThemeProvider>
              </LocalizationProvider>
            </MuiThemeProvider>
          </StyledEngineProvider>
        </CookiesProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}
