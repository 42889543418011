import { SITES_API, USER_API } from './config/constants';
import queryConfig from './config/queryConfig';
import axiosConfig from './config/axiosConfig';

const api = 'user';
const baseUrl = USER_API;

export default function userApi(isConnected = false) {
  return {
    async addOrganization(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'addOrganization',
        params
      });
    },
    async getEcowattOptout() {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'getEcowattOptout'
      });
    },
    async editEcowattOptout(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'editEcowattOptout',
        params
      });
    },
    async deleteMyAccount(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'deleteMyAccount',
        params
      });
    },
    async deleteUser(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'deleteUser',
        params
      });
    },
    async leaveOrganization(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'leaveOrganization',
        params
      });
    },
    async fetch() {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'fetch'
      });
    },
    async edit(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'edit',
        params
      });
    },
    async fetchLanguages(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'fetchLanguages',
        params
      });
    },
    async editLanguage(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'editLanguage',
        params
      });
    },
    async logout() {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'logout'
      });
    },
    async uninterceptedMe() {
      const uninterceptedAxiosInstance = axiosConfig.create();
      const query = await uninterceptedAxiosInstance.post(`${USER_API}/me`);
      return query;
    },
    // anonymous
    async createGroup(params) {
      const query = await axiosConfig.post(`${SITES_API}/add-group`, params);
      return query;
    },
    // anonymous
    async getPermissions(params) {
      const query = await axiosConfig.post(
        `${USER_API}/get-permission-list`,
        params
      );
      return query;
    },
    // anonymous
    async isConnected() {
      const query = await axiosConfig.post(`${USER_API}/is-connected`);
      return query;
    },
    // anonymous
    async getUrlAuthorization() {
      const query = await axiosConfig.post(
        `${USER_API}/get-url-authorization`,
        {
          referer: window.location.href
        }
      );
      return query;
    },
    // anonymous
    async authorize(params) {
      const query = await axiosConfig.post(`${USER_API}/authorize`, params);
      return query;
    },

    // anonymous
    async me() {
      const query = await axiosConfig.post(`${USER_API}/me`);
      return query;
    },
    // anonymous
    async registration(params) {
      const query = await axiosConfig.post(`${USER_API}/registration`, params);

      return query;
    },
    // anonymous
    async invitationRegistration(params) {
      const query = await axiosConfig.post(
        `${USER_API}/invitation-registration`,
        params
      );

      return query;
    },
    // anonymous
    async loginAs(params) {
      const query = await axiosConfig.post(`${USER_API}/login-as`, params);

      return query;
    },
    // anonymous
    async login(params) {
      const query = await axiosConfig.post(`${USER_API}/login`, params);

      return query;
    },
    // anonymous
    async recover(params) {
      const query = await axiosConfig.post(
        `${USER_API}/send-reset-password-email`,
        params
      );
      return query;
    },
    // anonymous
    async resetPassword(params) {
      const query = await axiosConfig.post(
        `${USER_API}/change-password`,
        params
      );
      return query;
    },
    // anonymous
    async validateEmail(params) {
      const query = await axiosConfig.post(
        `${USER_API}/validate-email`,
        params
      );
      return query;
    },
    // anonymous
    async sendEmailValidationEmail(params) {
      const query = await axiosConfig.post(
        `${USER_API}/send-email-validation-email`,
        params
      );
      return query;
    },
    async contact(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'contact',
        params
      });
    }
  };
}
