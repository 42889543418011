import PropTypes from 'prop-types';
import { metaProps } from './meta';
import { zoneProps } from './zones';
import { electricityMeterProps } from './electricityMeters';
import { gasMetersProps } from './gasMeters';

export const siteProps = PropTypes.exact({
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  code: PropTypes.string,
  totalArea: PropTypes.number,
  createdAt: PropTypes.string,
  station: PropTypes.bool,
  isHandledByEc: PropTypes.bool,
  isRemoteReadable: PropTypes.bool,
  hasHarmony: PropTypes.bool,
  harmonyLink: PropTypes.string,
  electricityMetersCount: PropTypes.number,
  gasMetersCount: PropTypes.number,
  hasAtLeastOneElectricityMeterWithData: PropTypes.bool,
  hasAtLeastOneUsableElectricityMeter: PropTypes.bool,
  hasAtLeastOneGasMeterRemoteReadable: PropTypes.bool,
  ssoClientCode: PropTypes.string,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string.isRequired
    })
  ),
  typology: PropTypes.number,
  address: PropTypes.shape({
    street: PropTypes.string,
    postcode: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string
  }),
  zones: PropTypes.arrayOf(zoneProps),
  electricityMeters: PropTypes.arrayOf(electricityMeterProps),
  gasMeters: PropTypes.arrayOf(gasMetersProps),
  openingHours: PropTypes.array
});

export const siteListProps = PropTypes.shape({
  data: PropTypes.arrayOf(siteProps),
  meta: metaProps
});
