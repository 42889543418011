export const TYPOLOGY_ARRAY = [
  {
    value: 1,
    label: 'typology.list.1'
  },
  {
    value: 2,
    label: 'typology.list.2'
  },
  {
    value: 3,
    label: 'typology.list.3'
  },
  {
    value: 4,
    label: 'typology.list.4'
  },
  {
    value: 5,
    label: 'typology.list.5'
  },
  {
    value: 6,
    label: 'typology.list.6'
  },
  {
    value: 7,
    label: 'typology.list.7'
  },
  {
    value: 8,
    label: 'typology.list.8'
  },
  {
    value: 9,
    label: 'typology.list.9'
  },
  {
    value: 10,
    label: 'typology.list.10'
  },
  {
    value: 11,
    label: 'typology.list.11'
  },
  {
    value: 12,
    label: 'typology.list.12'
  }
];

export const TYPOLOGY_LIST = {
  1: {
    value: 1,
    label: 'typology.list.1'
  },
  2: {
    value: 2,
    label: 'typology.list.2'
  },
  3: {
    value: 3,
    label: 'typology.list.3'
  },
  4: {
    value: 4,
    label: 'typology.list.4'
  },
  5: {
    value: 5,
    label: 'typology.list.5'
  },
  6: {
    value: 6,
    label: 'typology.list.6'
  },
  7: {
    value: 7,
    label: 'typology.list.7'
  },
  8: {
    value: 8,
    label: 'typology.list.8'
  },
  9: {
    value: 9,
    label: 'typology.list.9'
  },
  10: {
    value: 10,
    label: 'typology.list.10'
  },
  11: {
    value: 11,
    label: 'typology.list.11'
  },
  12: {
    value: 12,
    label: 'typology.list.12'
  }
};

export const DAY_LIST = {
  1: { id: 1, label: 'day_list.monday' },
  2: { id: 2, label: 'day_list.tuesday' },
  3: { id: 3, label: 'day_list.wednesday' },
  4: { id: 4, label: 'day_list.thursday' },
  5: { id: 5, label: 'day_list.friday' },
  6: { id: 6, label: 'day_list.saturday' },
  7: { id: 7, label: 'day_list.sunday' }
};

const unknown = 'unknown';

const pending = 'pending';
const unknownGasMeterNumber = 'unknown_gas_meter_number';
const accessDataRevoked = 'access_data_revoked';
const gasContractExpired = 'gas_contract_expired';
const malformedGasMeterNumber = 'malformed_gas_meter_number';
const malformedPostcode = 'malformed_postcode';
const postcodeNotMatchAddress = 'postcode_not_match_address';
const accessDataWaitingIncumbentValidation =
  'access_data_waiting_incumbent_validation';
const accessDataAlreadyExist = 'access_data_already_exist';
const requestAlreadyExistsWithGrdf = 'request_already_exists_with_grdf';

const gasMeterAdded = 'gas_meter_added';
const obsolete = 'obsolete';
const revoked = 'revoked';
const refused = 'refused';

const roleMissing = 'role_missing';
const technicalError = 'technical_error';
const missingOrgNameOrUserName = 'missing_organization_name_or_user_name';
const orgNameAndUserNameProvidedTogether =
  'organization_name_and_user_name_provided_together';
const malformedConsentEndDate = 'malformed_consent_end_date';
const accessDataStartDateSuperiorFromToday =
  'access_data_start_date_superior_from_today_date';
const accessDataEndDateSuperiorFromConsentEndDate =
  'access_data_end_date_superior_from_consent_end_date';
const inconsistentAccessDataPerimeter = 'inconsistent_access_data_perimeter';
const malformedIncumbentEmail = 'malformed_incumbent_email';
const missingPostCode = 'missing_postcode';
const missingIncumbentEmail = 'missing_incumbent_email';
const missingAccessDataPerimeter = 'missing_access_data_perimeter';
const technicalErrorOccurs = 'technical_error_occurs';
const requestSendedToGrdf = 'request_sended_to_grdf';

export const STATUS_LIST = {
  [unknown]: {
    value: unknown,
    label: 'gasmeters.status.unknown',
    edition_status: 'unauthorized'
  },
  [pending]: {
    value: pending,
    label: 'gasmeters.status.pending',
    edition_status: 'unauthorized'
  },
  [unknownGasMeterNumber]: {
    value: unknownGasMeterNumber,
    label: 'gasmeters.status.unknown_gas_meter_number',
    edition_status: 'grdf'
  },
  [accessDataRevoked]: {
    value: accessDataRevoked,
    label: 'gasmeters.status.access_data_revoked',
    edition_status: 'unauthorized'
  },
  [gasContractExpired]: {
    value: gasContractExpired,
    label: 'gasmeters.status.gas_contract_expired',
    edition_status: 'unauthorized'
  },
  [malformedGasMeterNumber]: {
    value: malformedGasMeterNumber,
    label: 'gasmeters.status.malformed_gas_meter_number',
    edition_status: 'grdf'
  },
  [malformedPostcode]: {
    value: malformedPostcode,
    label: 'gasmeters.status.malformed_postcode',
    edition_status: 'grdf'
  },
  [postcodeNotMatchAddress]: {
    value: postcodeNotMatchAddress,
    label: 'gasmeters.status.postcode_not_match_address',
    edition_status: 'grdf'
  },
  [accessDataWaitingIncumbentValidation]: {
    value: accessDataWaitingIncumbentValidation,
    label: 'gasmeters.status.access_data_waiting_incumbent_validation',
    edition_status: 'unauthorized'
  },
  [accessDataAlreadyExist]: {
    value: accessDataAlreadyExist,
    label: 'gasmeters.status.access_data_already_exist',
    edition_status: 'grdf_limited'
  },
  [requestAlreadyExistsWithGrdf]: {
    value: requestAlreadyExistsWithGrdf,
    label: 'gasmeters.status.request_already_exists_with_grdf',
    edition_status: 'grdf_limited'
  },
  [gasMeterAdded]: {
    value: gasMeterAdded,
    label: 'gasmeters.status.gas_meter_added',
    edition_status: 'grdf_limited'
  },
  [obsolete]: {
    value: obsolete,
    label: 'gasmeters.status.obsolete',
    edition_status: 'unauthorized'
  },
  [revoked]: {
    value: revoked,
    label: 'gasmeters.status.revoked',
    edition_status: 'unauthorized'
  },
  [refused]: {
    value: refused,
    label: 'gasmeters.status.refused',
    edition_status: 'unauthorized'
  },

  [roleMissing]: {
    value: roleMissing,
    label: 'gasmeters.status.role_missing',
    edition_status: 'unauthorized'
  },
  [technicalError]: {
    value: technicalError,
    label: 'gasmeters.status.technical_error',
    edition_status: 'unauthorized'
  },
  [missingOrgNameOrUserName]: {
    value: missingOrgNameOrUserName,
    label: 'gasmeters.status.missing_organization_name_or_user_name',
    edition_status: 'unauthorized'
  },
  [orgNameAndUserNameProvidedTogether]: {
    value: orgNameAndUserNameProvidedTogether,
    label: 'gasmeters.status.organization_name_and_user_name_provided_together',
    edition_status: 'unauthorized'
  },
  [malformedConsentEndDate]: {
    value: malformedConsentEndDate,
    label: 'gasmeters.status.malformed_consent_end_date',
    edition_status: 'unauthorized'
  },
  [accessDataStartDateSuperiorFromToday]: {
    value: accessDataStartDateSuperiorFromToday,
    label: 'gasmeters.status.access_data_start_date_superior_from_today_date',
    edition_status: 'unauthorized'
  },
  [accessDataEndDateSuperiorFromConsentEndDate]: {
    value: accessDataEndDateSuperiorFromConsentEndDate,
    label:
      'gasmeters.status.access_data_end_date_superior_from_consent_end_date',
    edition_status: 'unauthorized'
  },
  [inconsistentAccessDataPerimeter]: {
    value: inconsistentAccessDataPerimeter,
    label: 'gasmeters.status.inconsistent_access_data_perimeter',
    edition_status: 'unauthorized'
  },
  [malformedIncumbentEmail]: {
    value: malformedIncumbentEmail,
    label: 'gasmeters.status.malformed_incumbent_email',
    edition_status: 'unauthorized'
  },
  [missingPostCode]: {
    value: missingPostCode,
    label: 'gasmeters.status.missing_postcode',
    edition_status: 'grdf'
  },
  [missingIncumbentEmail]: {
    value: missingIncumbentEmail,
    label: 'gasmeters.status.missing_incumbent_email',
    edition_status: 'unauthorized'
  },
  [missingAccessDataPerimeter]: {
    value: missingAccessDataPerimeter,
    label: 'gasmeters.status.missing_access_data_perimeter',
    edition_status: 'unauthorized'
  },
  [technicalErrorOccurs]: {
    value: technicalErrorOccurs,
    label: 'gasmeters.status.technical_error_occurs',
    edition_status: 'unauthorized'
  },
  [requestSendedToGrdf]: {
    value: requestSendedToGrdf,
    label: 'gasmeters.status.request_send_to_grdf',
    edition_status: 'unauthorized'
  }
};

export const GAS_METER_TARIFF_LIST = {
  T1: {
    value: 'T1',
    label: 'gasmeters.tariff.T1'
  },
  T2: {
    value: 'T2',
    label: 'gasmeters.tariff.T2'
  },
  T3: {
    value: 'T3',
    label: 'gasmeters.tariff.T3'
  },
  T4: {
    value: 'T4',
    label: 'gasmeters.tariff.T4'
  },
  TP: {
    value: 'TP',
    label: 'gasmeters.tariff.TP'
  }
};

export const GAS_METER_FREQUENCE_LIST = {
  '1M': {
    value: '1M',
    label: 'gasmeters.frequence.1M',
    remote_reading_status: 'gasmeters.remote_reading.remote_read'
  },
  '6M': {
    value: '6M',
    label: 'gasmeters.frequence.6M',
    remote_reading_status: 'gasmeters.remote_reading.not_remote_read'
  },
  MM: {
    value: 'MM',
    label: 'gasmeters.frequence.MM',
    remote_reading_status: 'gasmeters.remote_reading.remote_read'
  },
  JJ: {
    value: 'JJ',
    label: 'gasmeters.frequence.JJ',
    remote_reading_status: 'gasmeters.remote_reading.remote_read'
  },
  JM: {
    value: 'JM',
    label: 'gasmeters.frequence.JM',
    remote_reading_status: 'gasmeters.remote_reading.remote_read'
  }
};

export const CONTACT_FORM_OBJECTS = [
  {
    value: 'explanation',
    label: 'contact_form.object.explanation'
  },
  {
    value: 'assistance',
    label: 'contact_form.object.assistance'
  },
  {
    value: 'improvement',
    label: 'contact_form.object.improvement'
  },
  {
    value: 'other',
    label: 'contact_form.object.other'
  }
];
