import { useEffect } from 'react';

import useNotification from 'eficia/hooks/useNotification';

// Hook pour afficher les notifications d'erreur d'un formulaire
export default function useFormErrorNotification(errors) {
  const { addNotification } = useNotification();
  const stringifiedErrorKeys = Object.keys(errors).join('');

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      const uniqueErrorKeys = [];
      const errorMessage = Object.values(errors).reduce((acc, curr) => {
        if (uniqueErrorKeys.includes(curr.message)) {
          return acc;
        }

        uniqueErrorKeys.push(curr.message);
        return curr.message ? acc.concat(curr.message, '<br/>') : acc;
      }, '');

      addNotification({
        message: errorMessage,
        type: 'error'
      });
    }
  }, [errors, addNotification, stringifiedErrorKeys]);
}
