import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function NodeSubHeader({ label }) {
  const { t } = useTranslation();

  return (
    <span
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '8px 16px 8px 16px',
        fontWeight: 'bold'
      }}
    >
      {t(label)}
    </span>
  );
}

NodeSubHeader.propTypes = {
  label: PropTypes.string.isRequired
};

export default NodeSubHeader;
