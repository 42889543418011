import styled from 'styled-components';

export const AppFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: ${({ withoutVisibleSidebar }) => (withoutVisibleSidebar ? '0' : '255px')};
  width: ${({ withoutVisibleSidebar }) => (withoutVisibleSidebar ? '100%' : 'calc(100% - 255px)')};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 12px;
`;
