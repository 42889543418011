import React from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Tag from 'eficia/components/atoms/Tag/Tag';
import ButtonGroup from 'eficia/components/atoms/ButtonGroup/ButtonGroup';

import './index.css';
import { Typography } from '@mui/material';

export default function TagsMenu({
  tags = [],
  onRemove,
  operator,
  onOperatorChange,
  operatorButtons,
  placeholder,
  badge,
  width
}) {
  return (
    <div style={{ margin: 16, marginBottom: 0 }}>
      <div className="tag-menu">
        <div className="tag-menu-content" style={{ width }}>
          {tags.length > 0 ? (
            <PerfectScrollbar options={{ wheelPropagation: false }}>
              {tags.map((tag, index) => (
                <Tag
                  badge={badge}
                  key={index}
                  onRemove={onRemove}
                  label={tag.label}
                  tagId={tag._id}
                />
              ))}
            </PerfectScrollbar>
          ) : (
            <Typography className="tag-placeholder">{placeholder}</Typography>
          )}
        </div>
      </div>
      <div className="operator-button">
        {operator && (
          <ButtonGroup
            active={operator}
            onChange={onOperatorChange}
            disabled={tags.length < 2}
            buttons={operatorButtons}
          />
        )}
      </div>
    </div>
  );
}

TagsMenu.propTypes = {
  width: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  onOperatorChange: PropTypes.func,
  onRemove: PropTypes.func,
  operatorButtons: PropTypes.array,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  operator: PropTypes.string,
  badge: PropTypes.bool.isRequired
};
