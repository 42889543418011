import { useCallback } from 'react';
import { useQuery } from 'react-query';

import eficiaClient from 'eficia/services/eficiaClient';

export function useFetchLanguages() {
  const client = eficiaClient();

  // Entrer en mode dégradé si le fetch échoue
  // Ceci afin d'éviter de bloquer l'affichage de la page
  const fallbackData = {
    data: {
      data: [{ iso: 'fr', isActive: true }]
    }
  };

  const fetcher = useCallback(async () => {
    try {
      const response = await client.user.fetchLanguages();
      if (!response?.data?.data?.length) {
        return fallbackData;
      }
      return response;
    } catch (error) {
      return fallbackData;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client.user]);

  const { data, error, isFetching } = useQuery(['languages'], fetcher);

  if (data?.data?.data?.length) {
    // Le Back envoie un tableau de langues avec un champ isActive (donc potentiellement des langues inactives)
    data.activeLanguages = data.data.data.filter(
      (language) => language.isActive
    );
    data.activeLanguages = data.activeLanguages.length
      ? data.activeLanguages
      : fallbackData.data.data;
  }

  return {
    data,
    error,
    isFetching
  };
}
