export const EFICIA_BASE_API = `${process.env.REACT_APP_EFICIA_API_URL}/api`;

// auth / user
export const USER_API = `${EFICIA_BASE_API}/user`;

// alerts
export const ALERTS_API = `${EFICIA_BASE_API}/enedis`;

// sites
export const ORGANIZATION_API = `${EFICIA_BASE_API}/organization`;
export const SITES_API = `${EFICIA_BASE_API}/site`;
export const GRDF_API = `${EFICIA_BASE_API}/grdf`;
export const ENEDIS_API = `${EFICIA_BASE_API}/enedis`;
export const DATADIS_API = `${EFICIA_BASE_API}/datadis`;

export const GROUPS_API = `${EFICIA_BASE_API}/groups`;

// translation (temp url)
export const TRANSLATION_API = `${EFICIA_BASE_API}/translation`;

// harmony
export const HARMONY_API = `${EFICIA_BASE_API}/harmony`;

// electricity
export const ELECTRICITY_API = `${EFICIA_BASE_API}/electricity`;

// dataviz
export const DATA_VISUALIZATION_API = `${EFICIA_BASE_API}/data-visualization`;

// chatbot
export const CHATBOT_BASE_API = process.env.REACT_APP_CHATBOT_API_URL;
