import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { parseQueryParams, stringifyQuery } from 'eficia/utilities/query';

import anonymousClient from 'eficia/services/anonymousClient';
import useNotification from 'eficia/hooks/useNotification';
import { HOME_PATH } from 'eficia/constants/paths';

export default function useChangeOrganization() {
  const client = anonymousClient();
  const { t } = useTranslation();
  const { addNotification } = useNotification();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const fetcher = useCallback(
    (data) => {
      const params = {
        organizationId: data.organizationId
      };

      return client.organization.change(params);
    },
    [client.organization]
  );

  const mutation = useMutation(fetcher, {
    onSuccess: async (response) => {
      const { data } = response;

      if (data?.data?.redirect_url) {
        window.location.replace(data?.data?.redirect_url);
        return;
      }

      queryClient.clear();

      const { organizationId } = parseQueryParams(search);

      if (organizationId) {
        navigate(
          `${pathname}${stringifyQuery({
            ...parseQueryParams(search),
            organizationId: null
          })}`
        );
      } else {
        navigate(HOME_PATH);
      }
    },
    onError: () => {
      addNotification({
        message: t(`organization.select.change.message.error`),
        type: 'error'
      });
    }
  });

  return mutation.mutate;
}
