import React from 'react';
import { PropTypes } from 'prop-types';
import { ButtonUi, ButtonGroupUi } from './ButtonGroup.style';

export default function ButtonGroup({
  buttons,
  disabled = true,
  active,
  onChange,
  ...rest
}) {
  const handleClick = (newValue) => {
    onChange(newValue);
  };
  return (
    <ButtonGroupUi disabled={disabled} {...rest}>
      {buttons.map(({ label, value }, index) => (
        <ButtonUi
          style={{
            textTransform: 'uppercase',
            borderColor: value === active ? '#224F7A' : '#ccc',
            color: value === active ? '#224F7A' : '#ccc',
            fontSize: 10
          }}
          onClick={() => handleClick(value)}
          key={index}
          variant="outlined"
          data-testid={rest?.['data-testid'] ? `${rest['data-testid']}-${value}` : undefined}
        >
          {label}
        </ButtonUi>
      ))}
    </ButtonGroupUi>
  );
}

ButtonGroup.propTypes = {
  disabled: PropTypes.bool,
  buttons: PropTypes.array.isRequired,
  active: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};
