import styled from 'styled-components';

export const SidebarHeaderMobileWrapper = styled.div`
  height: 160px;
  width: 264px;
  display: flex;
  align-items: center;
  background: white;
  padding-left: 16px;
`;

export const SidebarHeaderDesktopWrapper = styled.div`
  height: 80;
  width: 264px;
  display: flex;
  align-items: center;
  background: white;
  padding: 24px 24px 6px 24px;
`;
