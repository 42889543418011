import PropTypes from 'prop-types';

export const countryProps = PropTypes.exact({
  id: PropTypes.string,
  alpha2: PropTypes.string,
  displayOrder: PropTypes.number,
  localizedName: PropTypes.string,
  name: PropTypes.string,
  electricityKwhPrice: PropTypes.number,
  gasKwhPrice: PropTypes.number,
  waterLiterPrice: PropTypes.number,
  carbonEmissionsRatio: PropTypes.number,
  distributors: PropTypes.arrayOf(PropTypes.string),
  handleElectricity: PropTypes.bool,
  electricityCarbonEmissionsRatio: PropTypes.number
});

export const countryListProps = PropTypes.arrayOf(countryProps);

export const organizationProps = PropTypes.exact({
  id: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isGroupManager: PropTypes.bool.isRequired,
  managedGroupIds: PropTypes.arrayOf(PropTypes.string),
  managedSiteIds: PropTypes.arrayOf(PropTypes.string),
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  organizationId: PropTypes.string.isRequired,
  organizationName: PropTypes.string.isRequired,
  organizationHasSso: PropTypes.bool,
  organizationSubdomain: PropTypes.string,
  countries: countryListProps.isRequired,
  isUnknown: PropTypes.bool,
  lang: PropTypes.string,
  isSso: PropTypes.bool
});
